.FAQLP{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

   
}
.FAQLPContainer1{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 85%;
    padding-top: 5%;
    padding-bottom: 5%;


}
.FAQLPContainer1{
    // width:80%;
    height:100%;
    padding: 3% 0% 6%;
    // margin-left: 5%;
}

@media(max-width:540px){
    .FAQLPContainer1 h3{
        font-size: 14px;
    }
    .FAQLPContainer1 h1{
        font-size: 25px;
    }
    .FAQLPContainer1 h6{
        font-size: 14px;
    }
}
@media(max-width:415px){
    .FAQLPContainer1 h3{
        font-size: 12px;
    }
    .FAQLPContainer1 h1{
        font-size: 20px;
    }
    .FAQLPContainer1 h6{
        font-size: 12px;
    }
}