.ourProds {
    width: 100%;
    // margin: 15% 3%;
    display: flex;
  
    padding: 40px 0;
    justify-content: center;
  
  
}
.ourProds > div {
    display: flex;
    width: 90%;
    flex-direction: column;
    gap: 20px;
    }

.CardmainPro {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    //  max-width: 1700px;
}

.CardmainPro>a {
    display: flex;
    justify-content: center;
    align-items: center;
    // flex: 1 0 290px;
    min-width: 270px;
    width: 100%;
    max-width: calc(25% - 20px);

}

.CardStart {
    // max-width: 300px;
}

.ourProds > div {
display: flex;
width: 90%;
flex-direction: column;
gap: 20px;
}

@media screen and (max-width: 768px) {



    .CardmainPro>a {
        max-width: 100%;
    }

}

// .cardMedias{
//     // background-image: linear-gradient(to top, rgb(255 255 255 / 16%), rgb(255 255 255 / 0%));
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     width: 100%;
//     height: 100%;
//     position: relative;
// }

// @media(max-width:912px){
// .ourProds h4{
//     font-size: 30px;
// }

// .CardPRoduct{
//     width: 600px;
//     background-position: center;
//     background-size: cover;
//     justify-content: center;
//     align-items: center;
//     align-items: center;
//     height: 300px;
   
// }
// }
// @media(max-width:820px){

// .CardPRoduct{
//     width: 500px;
//     height: 300px;
   
// }
// }
// @media(max-width:768px){

// .CardPRoduct{
//     width: 400px;
//     height: 200px;
   
// }
// }
// @media(max-width:540px){
// .ourProds h4{
//     font-size: 25px;
// }
// .CardPRoduct{
//     width: 300px;
//     height: 200px;
// }
// }
// @media(max-width:415px){
// .ourProds h4{
//     font-size: 20px;
//     padding-bottom: 0;
// }
// .ourProds h6{
//     font-size: 12px;
//     padding-top: 1%;
// }
// .CardPRoduct{
//     width: 300px;
//     height: 180px;
   
// }
// }
