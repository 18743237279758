.OTPBox {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.OTPForm {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.OTPBox h3 {
    font-size: 35px;
    font-weight: 600;
}

.OTPItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    width: 100%;
}

.OTPItem h5 {
    font-size: 12px;
    font-weight: 500;
}


.OTPMailPart {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.OTPFormMob {
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.muiOTPPField {
    display: flex;
    width: 60%;
}
.muiOTPPField fieldset{
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #d0d0d0;
}
.muiOTPPField input{
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  padding-bottom: 5px;
  color: #868686;
}

.OTPItem .verifyButtonn {
    display: flex;
    width: 50%;
    text-transform: capitalize;
    background-color: #16469D;
} 
.OTPItem .verifyButtonn:hover {
    background-color: #1a56c4;
} 

.OTPItem .didntOTPText {
    font-size: 15px;
    color: #6b6b6b;
    font-weight: 500;
}

.OTPItem .didntOTPButton {
    background-color: none;
    text-transform: capitalize;
    padding: 0;
    color: #1a56c4;
}

.OTPItem .didntOTPButton:hover {
    background-color: none;
    color: #16469D;
}

@media(max-width:820px) {
    .OTPBox {
        width: 100%;
        height: 100vh;
    }
    
    .OTPFormMob {
        width: 100%;
    }
    
}
@media(max-width:380px) {
    .muiOTPPField input{
        font-size: 13px; 
    }
    
    .muiOTPPField {
        gap: 10px !important;
    }

    .OTPBox h3 {
        font-size: 25px;
        font-weight: 600;
    }

    .OTPItem img {
        width: 100px;
    }

    .OTPItem h5 {
        font-size: 10px;
        font-weight: 500;
    }
}