



.UloginBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

// *******************************OTP Design START**************************


.otp-input {
    border: none;
    padding: 0px;
    width: 16.1ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 2ch, 
      /* Increased gap between dashed lines */ transparent 0, transparent 3.0ch 
      /* Increased gap between dashed lines */ ) 0 100% / 14ch 2px no-repeat;

    font: 5ch "Droid Sans Mono", "Consolas", monospace;
    letter-spacing: 2.0ch;
    text-align: center;
    text-indent: -1;
    outline: none;
    cursor: none;
    caret-color: transparent;
}


.otp-input:focus {
    color: dodgerblue;
    cursor: none;
    pointer-events: none;
}

// *******************************OTP Design END**************************

.Coporateverification {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-color: #F6FAFF;
    height: 100vh;

}

.BoxInfo {
    width: 100 !important;
    text-align: center;
    margin-top: 10%;
}

.BoxInfo h4 {
    width: 100 !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.BoxInfo h6 {
    width: 100 !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


@media(max-width:820px) {
    .Coporateverification {
        display: none;
    }
}