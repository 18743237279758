.ProjectDescPageMainInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(https://res.cloudinary.com/dxluokypg/image/upload/v1679738257/Indian_Tricolor_Flag_Png_Free_Download_-_1280x915_1_akzpik.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.ProjectDescPageMainContent {
    width: 85%;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
}


.ProjectDescPageMainContent > div > * {
    font-size: 18px;
    color: #5e5e5e;
    font-weight: 400;
    line-height: 1.7;
  
}
.ProjectDescPageMainContent > div > ul, .ProjectDescPageMainContent > div > ol
{
padding: 0 20px;
}

// .ProjectDescPageMainContent ul li {
//     font-size: 18px;
//     color: #7e7e7e;
//     font-weight: 700;
//     padding-bottom: 2%;
// }

// .ProjectDescPageMainContent ol li {
//     font-size: 18px;
//     color: #7e7e7e;
//     font-weight: 700;
//     padding-bottom: 2%;
// }

@media (max-width: 768px) {
    .ProjectDescPageMainInfo {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
    }

    .ProjectDescPageMainContent {
        display: flex;
        width: 85%;
    }
    // .ProjectDescPageMainContent h5 {
    //     font-size: 20px !important;
    //     font-weight: 410 !important;
    //     color: #585858;
    // }
    // .ProjectDescPageMainContent h6 {
    //     font-size: 15px;
    // }
    // .ProjectDescPageMainContent ol li {
    //     font-size: 15px;
    // }
    // .ProjectDescPageMainContent ul li {
    //     font-size: 15px;
    // }
}
// .projectLink{
//     display: flex;
//     justify-content: center;
//     max-width: 30%;
// }
// .ProjectDescPage {
//     width: 24%;
//     display: flex;
//     flex-direction: column;
// }