.backimg {
  margin-top: 10%;
  height: auto;
  background-image: url(../../../assets/images/backimg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUs {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.aboutBox1 {
  width: 48%;
}

.aboutUs_head {
  font-size: 30px;
  font-weight: 700;
  line-height: 23px;
  color: #585858;
}

.imgContainer {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.aboutUs_para {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #585858;
  padding-top: 8%;
}

.aboutUs_para a {
  text-decoration: none;
}

.aboutBox2 {
  width: 45%;
}

@media (min-width: 1535px) {
  .aboutUs {
    margin-top: 8%;
  }
}
@media (max-width: 1030px) {
  .aboutUs_head {
    padding-top: 2%;
  }
  .aboutUs_para {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #585858;
  }
}
@media (max-width: 769px) {
  .aboutUs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .aboutBox1 {
    display: flex;
    width: 90%;
  }
  .aboutBox2 {
    width: 90%;
  }
}
@media (max-width: 450px) {
  .aboutUs_head {
    text-align: center;
    font-size: 20px;
    color: #585858;
    font-weight: 600;
  }
}
@media (max-width: 416px) {
  .aboutBox2 .aboutUs_head {
    width: 100%;
    text-align: start;
    margin-top: 2%;
    font-size: 20px !important;
  }
  .aboutBox2 .aboutUs_para {
    width: 100%;
    text-align: start;
    margin-top: 2%;
    font-size: 12px !important;
  }
  .backimg {
    margin-bottom: 5%;
  }
  .aboutUs_para {
    padding-top: 4%;
  }
}/*# sourceMappingURL=AboutUs.css.map */