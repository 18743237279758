.banner {
    width: 100%;
}

.slider {
    width: auto;
}

.bannerinner {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box !important;
}

.bannertext {
    width: 50%;
    color: white;
    padding-top: 10%;
    padding-left: 9%;
    box-sizing: border-box !important;
    font-style: normal;
    display: flex;
    flex-direction: column;
}

.kmBtn {
    border: 1px solid #16469D;
    background-color: #16469D;
    color: white;
    box-sizing: border-box !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.06em;
    width: 150px;
    margin-top: 5%;
    padding: 0.8rem;
    border-radius: 4px;
    // margin-bottom: 25%;
    margin-left: 0%;
    box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.boxCard4 {
    width: 100%;
    z-index: 999;
}

.dot {
    background-color: #16469D;
    border-radius: 50%;
}
.innerBox4
{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   width: 100%;
   max-width: 1500px;
   padding-left: 40px !important;
   padding-right: 40px !important;
   
}
.innerBox4 > div
{
   min-width: 250px;

    // width: 20%;
    width:  25%;
    
  
    display: flex;
}
.innerBox4 > div > div
{
    width: 100%;
}
.card1 {
    border-radius: none !important;
   
}

@media screen and (max-width:1200px) {

    .innerBox4
{
    
  padding: 10px 20px !important;
   
}
    .innerBox4 > div
    {
      
        width:  50%;
        
      
    }
    
}

.CouterBox {
    display: flex;
    width: 20% !important;
}

.btnLink {
    margin-top: 5%;
    // width: 20%;
}


.CounterBoxMAin {
    display: none;
    justify-content: center;
    width: 100%;
}

.CounterStart2 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

.CounterStart2 .CardBox {
    width: 500px;
    height: 30vh;
}
@media (min-width:1140px) {
    
.btnLink {
    width: 20%;
}
}
@media(max-width:1140px) {
    // .card1 {
    //     margin: 2%;
    //     border-radius: 5px !important;
    // }

    .bannerinner {
        width: 100%;
        height: 70vh;
        display: flex;
        position: relative;
    }

    .bannertext {
        position: absolute;
        // left: 10%;
        bottom: 5%;
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;

    }
}

@media(max-width:1000px) {
    .bannerinner {
        width: 100%;
        height: 60vh;
    }
}

@media(max-width:800px) {
    .bannerinner {
        width: 100%;
        height: 50vh;
    }
}

@media(max-width:768px) {
    .bannerinner {
        width: 100%;
        height: 40vh;
        display: flex;
    }

   

    .bannerinner .btnLink {
        // width: 100%;
        display: flex;
        justify-content: start !important;
        align-items: start !important;
        text-decoration: none !important;
        margin-top: 0 !important;
        margin-bottom: 4%;
    }

    .bannertext h6 {
        padding-top: 3% !important;
    }

    .bannerinner button {
        // width: 40%;
        padding: 1% !important;
        font-size: 12px !important;


    }
}

@media(max-width:600px) {

    .boxCard4 {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .bannerinner {
        width: 100%;
        height: 35vh;
    }

}

@media(max-width:416px) {
    .boxCard4 {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 100px !important;
    }

    // .card1 {
    //     border-radius: 5px !important;

    // }

    .bannerinner {
        display: flex;
        position: relative;
        height: 40vh;
    }

    .bannertext {
        position: absolute;
        left: 5%;
        bottom: 5%;
        width: 90% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;

    }

    .btnLink {
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .bannertext h3 {
        width: 100%;
        padding-bottom: 0 !important;
        font-size: 24px;
        line-height: 30px;
    }

    .bannertext h6 {
        width: 100%;
        padding-top: 4% !important;
        font-size: 14px;
        line-height: 20px;
    }

    .bannerinner button {
        font-size: 12px !important;
        // width: 30%;
        padding:2.5% 0 !important;
    }

    .CounterBoxMAin {
        display: flex;
        justify-content: center;
        margin-top: 5%;

    }

    .CounterStart2 {
        width: 90%;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        flex-wrap: wrap;
        margin-top: 5%;

    }

    .CounterStart2 .CardBox {
        width: 160px;
        height: 12vh;
    }

    .CounterStart2 .CardBox h6 {
        padding-bottom: 15%;
        font-weight: 700;
        font-size: 35px !important;
        line-height: 20px;
        color: #16469D;
    }
    .CounterStart2 .CardBox h5 {
        padding-bottom: 10%;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        color: #000000;
    }

    .boxCard4 {
        display: none !important;
    }
    .bannerinner {
        width: 100%;
        height: 60vh;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
    }
}

@media(max-width:360px){
    .CounterStart2 .CardBox {
        width: 140px;
        height: 12vh;
    }
    .CounterStart2 .CardBox h6 {
        padding-bottom: 12%;
        font-size: 25px;
    }
    .CounterStart2 .CardBox h5 {
        padding-bottom: 8%;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }
}