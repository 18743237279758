.contactUsBannerMain {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.contactUsBanner {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 40vh;
  background-image: url("https://res.cloudinary.com/dxluokypg/image/upload/v1683263931/Contact-us-_e5spmc.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-right: 15%;
}

.TextInput12345 {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  resize: none;
  text-indent: 10px;
  padding: 1% 0;
  border-radius: 5px;
  border: 1px solid rgb(196, 196, 196);
}

.TextInput12345:focus {
  border: 2px solid #16469D !important;
}

.TextInput12345::-moz-placeholder {
  color: #7d7d7d;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.TextInput12345::placeholder {
  color: #7d7d7d;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.contactUsBannerForm {
  width: 35%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  background-color: #F1F1F1;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  padding: 3%;
  margin: 5% 0% 8%;
}

.SubmitBtn:hover {
  background-color: #2458B7 !important;
}

.SubmitBtn:focus {
  background-color: #4a4a4b !important;
  display: block;
}

.ContactFormStart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ContactFormStart > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1300px;
  padding: 0 10px;
  gap: 25px;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
}

.ContactBox1 {
  display: flex;
  /* width: 50%; */
  flex: 1 0 300px;
  flex-direction: column;
  gap: 20px;
  background-color: #F6FAFF;
  padding: 2%;
}

.ContactBox1 > div {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.FormInput {
  display: flex;
  flex-direction: column;
  flex: 1 0 300px;
  height: 100%;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .ContactFormStart > div {
    flex-direction: column-reverse;
  }
}/*# sourceMappingURL=ContactUs.css.map */