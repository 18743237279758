.element.style {
  margin-right: -20px;
}

.UloginMain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.thirdModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thirdModal button {
  width: 60%;
  padding: 3% 0;
}

.otpInput {
  width: 40%;
  margin-bottom: 5%;
}

.otpInput input {
  padding: 10% 0 !important;
  width: 100% !important;
  text-indent: 10px;
}

.UloginStart {
  display: flex;
  width: 100%;
}

.UloginBox {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UloginForm {
  width: 60%;
  display: flex;
  flex-direction: column;
  border-bottom: none !important;
}

.UloginBox2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  background-color: #F6FAFF;
  height: 100vh;
}

.UloginBox input:focus {
  border: 1.5px solid #000 !important;
}

.UloginBox textarea:focus {
  border: 1.5px solid #000 !important;
}

.UloginForm label {
  color: #606778;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.UloginForm .box1 {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.UloginFormButton > * {
  height: 40px;
}

#root > div > div > div > form > div > div.UloginBoxForm > div:nth-child(2) > div > div > input {
  border-radius: 5% 0 0 5% !important;
}

.UloginFormButton button {
  width: 100% !important;
  color: #fff;
  border-radius: 0 6px 6px 0 !important;
  border: 2px solid #16469d !important;
  margin: 0 !important;
  height: 100%;
}

.UloginFormButton button:hover {
  background-color: #1976d2 !important;
  border: 2px solid #1976d2 !important;
  color: #fff;
}

.box3 select {
  width: 100%;
}

.box1 h1 {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  padding: 0 !important;
}

.box1 h2 {
  text-align: start !important;
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 !important;
}

.box1 h2 span {
  color: #16469D;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.box2 {
  display: block;
  border-bottom: none;
  width: 100% !important;
}

.box2 h1 {
  color: #7B8499;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: block;
}

.box2 h1 span {
  color: #12141A;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 145.455% */
  text-decoration-line: underline;
}

.box2 h2 {
  color: #7B8499;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.box2 h2 span {
  color: #16469D;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
}

.UloginBoxForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.UloginBoxForm > div {
  display: flex;
  flex-direction: column;
}

.UloginBoxForm > div > input {
  width: 85%;
  border-radius: 6px;
  border: 1px solid #CAD0DB;
  text-indent: 10px;
  margin-bottom: 0 !important;
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.UloginBox input::-moz-placeholder {
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.UloginBox input::placeholder {
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.UloginBox button {
  width: 40%;
  border-radius: 10px;
  text-transform: capitalize;
  background: #16469D;
  margin: 5% 0;
}

.UloginBox a {
  text-align: end !important;
  color: #16469D;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* padding-bottom: 8%; */
}

.UloginBox h5 {
  text-align: start !important;
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.Uloginimage h4 {
  color: #2B2727;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
}

.Uloginimage h6 {
  color: #606778;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none !important;
}

.UloginBox h5 span {
  color: #16469D;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.UloginBox .Uloginimage {
  color: #16469D;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.selecttab {
  padding: 3% 0;
  border-radius: 6px;
  border: 1px solid #CAD0DB;
}

.selecttab option {
  padding: 3% 0;
  font-size: 15px;
}

.box1 textarea {
  resize: none;
  border-radius: 6px;
  border: 1px solid #CAD0DB;
  padding: 2%;
}

/* ----------------------drop down--------------- */
.selectwrapper {
  width: 100%;
  margin-bottom: 5%;
}

.thirdModalsecound h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3%;
  margin-top: 1%;
}

.thirdModalsecound h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1%;
}

.thirdModalsecound button {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 1%;
}

.selectdropdown {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
}

.selectdropdown:focus {
  border-color: #6c63ff;
  outline: none;
}

.selectoptions {
  display: none;
  position: absolute;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.selectoptions {
  padding: 20px !important;
  cursor: pointer;
}

.selectoptions:hover {
  background-color: #6c63ff;
  color: white;
}

.BoxInfo {
  width: 100 !important;
  text-align: center;
  margin-top: 10%;
}

.BoxInfo h4 {
  width: 100 !important;
  color: #2B2727;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BoxInfo h6 {
  width: 100 !important;
  color: #606778;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.UloginBox2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  background-color: #F6FAFF;
  height: 100vh;
}

.UloginBoxStart2 {
  width: 50%;
}

.UloginBoxStart2 .UloginMediaBox2 {
  width: 100% !important;
}

.UloginMediaBox2 img {
  border-radius: 2%;
  width: 100%;
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.UloginBoxForm {
  width: 100% !important;
}

.UloginBoxStart2 ul .slick-active {
  width: 40px !important;
}

.UloginBoxStart2 ul .slick-active button {
  width: 40px !important;
}

.UloginBoxStart2 .slick-dots li button:before {
  width: 15px !important;
  content: "";
  background-color: gray;
  height: 5px;
  border-radius: 6px;
}

.UloginBoxStart2 ul .slick-active button::before {
  width: 40px !important;
  content: "";
  background-color: black !important;
  height: 5px;
}

@media (max-width: 1360px) {
  .UloginForm {
    width: 80% !important;
  }
  .BoxInfo h4 {
    font-size: 24px;
  }
  .BoxInfo h6 {
    font-size: 19px;
  }
}
@media (max-width: 998px) {
  .UloginBox2 {
    display: none !important;
  }
  .UloginBox {
    width: 100%;
  }
  .UloginForm {
    width: 80%;
  }
}
@media (max-width: 415px) {
  .UloginBoxForm > div > input {
    width: 100%;
    align-items: left;
  }
  .phoneinputtt input {
    text-align: left;
    font-size: 10px;
  }
}
@media (max-width: 415px) {
  .UloginFormButton {
    display: flex;
    width: 90% !important;
  }
  .UloginBoxForm input {
    padding: 4% 0;
  }
  .box1 h1 {
    font-size: 20px;
  }
  .react-tel-input .form-control {
    width: 200px;
  }
  .box2 button {
    margin-top: 10%;
    width: 50%;
  }
  .thirdModal {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thirdModal button {
    width: 50% !important;
  }
  .otp-input {
    border: none;
    padding: 0px;
    width: 16.1ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 2ch, transparent 0, transparent 3ch) 0 100%/14ch 4px no-repeat;
    font: 5ch "Droid Sans Mono", "Consolas", monospace;
    letter-spacing: 2ch;
    text-align: center;
    text-indent: -1;
    outline: none;
    cursor: none;
    caret-color: transparent;
  }
  .otp-input:focus {
    color: dodgerblue;
    cursor: none;
    pointer-events: none;
  }
}/*# sourceMappingURL=CorporateRegistration.css.map */