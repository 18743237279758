.css-1otls7k-MuiTypography-root {
    font-size: 14px !important;
}

.MuiMenu-list {
    padding: 5%;
}

.menuMob {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: rgba(88, 88, 88, 1);
    background-color: white;
    text-align: left;
}

.NavBarStartMain {
    width: 100%;
}

.css-125bi9j-MuiTypography-root {
    padding: 3%;
}

.menuMob:hover {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: white;
}

.NavBarStartMain img {
    margin-left: 0 !important;
}

.css-18tat4p {
    margin-right: 0 !important;
}

.NavBarStartMain2>div>a>p {
    font-size: 14px !important;
    color: #001926 !important;
}

.dropDownLink {
    position: relative;


}
.dropDownLink Button{
   padding: 0 !important;
   font-size: 14px !important;
}

.dropDownMenu {
    position: absolute;
    background-color: white;
    // padding: 20px 15px;
    display: none;
    top: 100%;
    left: 0;
    min-width: 150px;
    flex-direction: column;
    -webkit-box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.22);
    list-style: none;
    z-index: 10;


}

.dropDownMenu::before
{
   z-index: -1;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid white;
    top: -10px;
    position: absolute;
    left: 7px;
}

.dropDownLink:hover .dropDownMenu {
    display: flex;

}

.dropDownMenu a {
    text-decoration: none;
    font-size: 14px;
    color: rgb(0, 25, 38);
    width: 100%;
    padding: 10px 15px;
    width: 100%;
    transition: all 0.2s ease-in-out;
}
.dropDownMenu a:hover
{
    background-color: #f6faff;
    // color: white;
}




@media(max-width:1200px) {
    .NavBarStartMain {
        width: 100%;
        display: flex;
        margin: 0 !important;
        justify-content: space-between;
    }

    img {
        margin: 0 !important;
    }

    .css-18tat4p {
        width: 100%;
        justify-content: space-between;
        display: flex;
    }

    .css-18tat4p a p {
        font-size: 14px !important;
        padding: 0 !important;
        margin: 0 !important;

    }


}

@media(max-width:1050px) {
    .NavBarStartMain {
        width: 100%;
        display: flex;
        margin: 0 !important;
        justify-content: space-between;
    }

    img {
        margin: 0 !important;
    }


    .css-18tat4p {
        width: 100%;
        justify-content: space-between;
        display: flex;
    }

    .css-18tat4p a p {
        font-size: 14px !important;
        padding: 0 !important;
        margin: 0 !important;

    }
}

@media (max-width:900px) {
    .css-18tat4p {
        display: none !important;
    }

}

@media (max-width:415px) {
    .NavBarStartMain img {
        width: 60%;
    }

}