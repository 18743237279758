.FaqsMainStart {
  width: 100%;
}

@media (max-width: 912px) {
  .FaqsMainBox {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .FaqsMainBox {
    flex-direction: column;
    width: 90%;
  }
  .FaqsMainBox button {
    width: 25%;
  }
}
@media (max-width: 540px) {
  .FaqsMainBox button {
    width: 30%;
  }
}
@media (max-width: 450px) {
  .css-d88t67 {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1.5px;
  }
  .css-xhw5y8-MuiTypography-root {
    font-size: 15px;
  }
  .FaqsModalButton {
    width: 100%;
    justify-content: space-between;
  }
  .buttonInModal {
    font-size: 12px;
    padding: 8px 10px;
  }
}
@media (max-width: 415px) {
  .FaqsMainBox button {
    width: 35%;
  }
  .css-d88t67 {
    width: 350px;
  }
  .css-xhw5y8-MuiTypography-root {
    font-size: 15px;
  }
  .FaqsModalButton {
    width: 100%;
    justify-content: space-between;
  }
  .css-qrly3q-MuiButtonBase-root-MuiButton-root {
    padding: 5px 2px;
  }
}
@media (max-width: 360px) {
  .FaqsMainBox button {
    width: 40%;
  }
}/*# sourceMappingURL=FAQPage.css.map */