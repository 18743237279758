.Schedule{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
}
.ScheduleContent{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ScheduleBox1 {
    width: 40%;   
}
.ScheduleBox2 img{
    display: flex;
    width: 60%;
    margin-top: 3%;
}

.ScheduleBox2{
    width: 60%;
    display: flex;
    justify-content: center;
}
.ScheduleBoxHide{
    display: none;
}

@media(max-width:1024px){
    .ScheduleBox1 {
        width: 45%;   
    }
    .ScheduleBox2 {
        display: flex;
        width: 50%;
    }
    .ScheduleBox2 img{
        display: flex;
        width: 80%;
    }
}
@media(max-width:912px){
    .ScheduleBox1 h3 {
        font-size: 25px;
    }
    .ScheduleBox1 h5 {
        font-size: 15px;
    }
    .ScheduleBox1 button{
        width: 50%;
    }
}
@media(max-width:820px){
    .ScheduleBox1 {
        width: 85%;
        margin-bottom: 4%;
    }
    .ScheduleBox2 {
        width: 85%;
    }
    .ScheduleBox1 h3 {
        font-size: 25px;
    }
    .ScheduleBox1 h5 {
        font-size: 15px;
    }
    .ScheduleBox1 button{
        width: 30%;
    }
    .ScheduleContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media(max-width:540px){

    .ScheduleBox1 h3 {
        font-size: 20px;
    }
    .ScheduleBox1 h5 {
        font-size: 12px;
    }
    .ScheduleBox1 button{
        width: 40%;
    }
}
@media(max-width:415px){
    .ScheduleBox1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ScheduleBox1 h3 {
        text-align: center;
        padding-top: 10%;
        font-size: 20px;
    }
    .ScheduleBox1 h5 {
        text-align: center;
        font-size: 12px;
        padding-bottom: 10%;
    }
    .ScheduleBox1 button{
        width: 40%;
        font-size: 12px;
        padding: 4% 0 !important;
        margin-bottom: 10%;
    }
    .ScheduleBoxHide{
        display: block;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 4%;
        margin-bottom: 10%;
    }
    .ScheduleBoxHide img{
        display: flex;
        width: 90%;
    }
    .ScheduleBox2{
        display: none;
    }
}

