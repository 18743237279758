.mainClass {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #2680BD 0%, rgba(86, 192, 229, 0.79) 50.52%, rgba(135, 203, 254, 0.56) 100%);
}

.firstBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  padding: 2% 5% 3% 5%;
  border-radius: 4px;
  background-color: white;
  box-shadow: -1px 3px 18px 2px rgba(0, 0, 0, 0.25);
}

.firstBox h1 {
  display: flex;
  justify-content: center;
  color: #302D2D;
  font-size: 30px;
  font-weight: 590;
}

.imgBox {
  margin-top: 7%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.firstBox h2 {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  color: #302D2D;
  font-size: 14px;
}

.firstBox h3 {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  color: #929292;
  font-size: 14px;
}

.firstBox a {
  padding-left: 1%;
  color: #3578DE;
  font-size: 14px;
  cursor: pointer;
}

.mainClass {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #2680BD 0%, rgba(86, 192, 229, 0.79) 50.52%, rgba(135, 203, 254, 0.56) 100%);
}/*# sourceMappingURL=OTPVerification.css.map */