.termsmain {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 5% 0;
}

.termsmainStart {
    display: flex;
    flex-direction: column;
    width: 85%;
}

.termsHeader {
    width: 100%;
    border-bottom: 2px solid #585858;
}
.termsBox{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
}

.termsHeader h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 58px;
    color: #585858;
}

.termsHeader h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #16469d;
}

.termsHeader h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #585858;
    padding-bottom: 1%;
}

.termscontent {
    color: #585858;
    margin-top: 1%;
    text-align: justify;
}



@media(max-width:540px){
    .termsHeader h2{
        font-weight: 700;
        font-size: 25px;
        line-height: 55px;
        color: #585858
    }
    .termsHeader h3{
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #16469d;
    }
    .termsHeader h4{
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: #585858;
        padding-bottom: 1%;
    }
    .termscontent{
        font-size: 15px;
    }

    
}
@media(max-width:415px){
    .termsHeader h2{
        font-weight: 700;
        font-size: 20px;
        line-height: 50px;
        color: #585858
    }
    .termsHeader h3{
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        color: #16469d;
    }
    .termsHeader h4{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #585858;
        padding-bottom: 1%;
    }
    .termscontent{
        font-size: 12px;

    }
}