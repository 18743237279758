
  .RateContractMainPage{
    background-image: url(../../../assets/images/IndianTricolorFlag.svg);
    background-size: cover;
    background-repeat: no-repeat;
     width: 100%;
    height: 100%;
    background-position: center;
    padding: 40px 0;
  }

  .rateIcon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:#16469d;
    color:  #F6FAFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media(max-width:540px){
    .abs{
      width: 100% !important;
    }
    .abs button{
      margin-top: 4%;
    }
    .abs h2{
      font-size: 20px;
    }
    .ContactUs{
      width: 100%;
      display: flex;
      justify-content: flex-start !important;
      text-align: start;
      align-items: flex-start;
    }
    .RateBox{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .ContactUsMainPage h1{
      font-size: 20px;
    }
    
  .RateContractMainPage{
    padding: 10px 0;
  }

  }
  @media(max-width:415px){
    .rateIcon{
      width: 30px;
      height: 30px;
    }
    .abs{
      width: 100% !important;
    }
    .abs button{
      margin-top: 4%;
    }
    .ContactUs{
      width: 100%;
      display: flex;
      justify-content: flex-start !important;
      text-align: start;
      align-items: flex-start;
    }
    .RateBox{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .abs h2{
      font-size: 20px;
    }
    .abs h6{
      font-size: 12px;
    }
    .working-hours h3{
      font-size: 15px;
    }
    .working-hours h6{
      font-size: 12px;
    }
    .RateBox h5{
      font-size: 15px;
    }
    .RateBox h6{
      font-size: 12px;
    }
  }