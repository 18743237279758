:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.projectComp {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.projectCompContent {
    width: 100%;
    display: flex;
    background-color: #F7FAFF;
}

.projectCompText {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 4% 0 0 2%;
    box-sizing: border-box;
   
}

.ProjectImgStart {
    width: 50%;
    display: flex;
}

.project_head {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    line-height: 23px;
    color: #585858;
    padding: 10% 0% 0% 9%;
}

.project_para {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    color: #585858;
    padding: 3% 2% 2% 9%;
    width: 80%;
    box-sizing: border-box;
}

.project_btn {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    width: 139px;
    padding: 1.2% 1.5%;
    background-color: var(--col);
    color: var(--wt);
    margin: 2% 10% 5% 9%;
    text-transform: none;
}

.project_btn:hover {
    background-color: var(--hov);
    color: var(--wt);
}

.projectImgfirst {
    width: 50%;
    display: flex;
    overflow: hidden;
}

.projectImgsecond {
    width: 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;


}

.projectImgfirst .projectImg1 {
    width: 100%;
    height: 100vh;
  
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    cursor: pointer;
    transition: 0.6s ease-in-out;
}

.projectImgfirst .projectImg1:hover {
    transform: scale(1.1) !important;
}
.projectImgsecond .projectImg2:hover {
    transform: scale(1.1) !important;
}
.projectImgsecond .projectImg3:hover {
    transform: scale(1.1) !important;
}


.projectImgsecond .projectImg2 {
    display: flex;
    width: 100%;
    height: 50vh;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
 
    position: relative;
    cursor: pointer;

}

.projectImgsecond .projectImg3 {
    display: flex;
    width: 100%;
    height: 50vh;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    // background: linear-gradient(to bottom,
    //         rgba(0, 0, 0, 0),
    //         rgba(0, 0, 0, 0.83)),
    //     url('../../../assets/images/projectImg3.svg');
    // background: linear-gradient(to bottom,
    //         rgba(0, 0, 0, 0),
    //         rgba(0, 0, 0, 0.83)),
    //     url('../../../assets/images/projectImg3.svg');
    position: relative;
    cursor: pointer;

}

.projectImg1 h4,
.projectImg2 h4,
.projectImg3 h4 {
    position: absolute;
    bottom: 8%;
    left: 8%;
    font-weight: 510;
    font-size: 18px;
    line-height: 22px;
    color: var(--wt);
}

.project_headM {
    display: none;

}

.project_headHide {
    display: none;
}

////////////////mkobile view/////////
@media (max-width: 913px) {
    .projectImgfirst .projectImg1 {
        width: 100%;
        height: 50vh;
    }

    .projectImgsecond .projectImg2,
    .projectImgsecond .projectImg3 {
        width: 100%;
        height: 25vh;
    }
}

@media (max-width: 768px) {
    .projectCompContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 5%;
        gap: 20px;
        padding-top: 40px;
    }

    .projectCompText {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding: 0;
        gap: 15px;
        
    }
    .project_btn {
    margin: 0;
    }
    .project_para {
        width: 100%;
        padding: 0;
    }

    .ProjectImgStart {
        width: 90%;
        display: flex;
       
    }

}

// @media (max-width: 600px) {
//     .projectImgfirst .projectImg1 {
//         width: 100%;
//         height: 60vh;
//     }

//     .projectImgsecond .projectImg2,
//     .projectImgsecond .projectImg3 {
//         width: 100%;
//         height: 30vh;
//     }

// }

// @media (max-width: 450px) {
//     .projectCompText .project_head {
//         width: 90%;
//         margin: 2% 0;
//         text-align: center;
//     }

//     .project_btnMain {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         margin: 5% 0;
//     }
// }

// @media (max-width: 416px) {
//     .ProjectImgStart {
//         width: 90%;
//         display: flex;
//         flex-wrap: wrap;

//     }

//     .projectComp {
//         background-color: #F7FAFF;
//         margin-top: 20%;
//         padding: 10% 0;
//     }

//     .project_head {
//         font-size: 20px;
//         width: 100%;
//         text-align: start !important;
//         padding: 10% 0% 10% 3%;
//         display: none;

//     }

//     .project_para {
//         font-size: 12px;
//         width: 100%;
//         line-height: 20px;
//         padding: 3% 3% 0% 3%;
//     }

//     .project_btnMain {
//         width: 95%;
//         display: flex;
//         justify-content: start;
//     }

//     .project_btn {
//         padding: 2.5% 1.5%;
//         margin: 2% 10% 5% 3%;
//         font-size: 12px;
//         width: 30%;
//     }


//     .projectImgfirst {
//         width: 100%;
//         display: flex;
//         margin-bottom: 1%;
//     }

//     .projectImgsecond {
//         width: 100%;
//         display: flex;
//         gap: 4px;
//         flex-direction: row;
//     }

//     .projectImgfirst .projectImg1 {
//         width: 100%;
//         height: 20vh;
//         border-radius: 4px 4px 0 0;

//     }

//     .projectImgsecond .projectImg2,
//     .projectImgsecond .projectImg3 {
//         width: 50%;
//         height: 20vh;
//         border-radius: 0 0 4px 4px;

//     }

//     .projectComp {
//         margin-bottom: 10%;
//     }

//     .projectImg1 h4 {
//         left: 4% !important;
//     }

//     .projectCompContent {
//         display: flex;
//         flex-direction: column-reverse;

//     }

//     .project_headHide {
//         font-size: 20px;
//         font-weight: 600;
//         color: #585858;
//         margin-bottom: 5%;
//         width: 100%;
//         text-align: start !important;
//         padding: 5% 0% 0% 0;
//         display: block;
//     }

// }




@media screen and (max-width: 768px) {
    
    .project_head
    {
      padding: 10px 0;
    }
}