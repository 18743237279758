.USerLOginMAin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.USerLOginStart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.USerLOginBox {
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.USerLOginFOrm {
  width: 45% !important;
  display: flex;
  flex-direction: column;
}

.USerLOginFOrm h3 {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  padding-bottom: 4%;
}

.USerLOginFOrm label {
  color: #606778;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.USerLOginFOrm h5 span {
  color: #16469D;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
}

.USerLOginFOrm input {
  width: 85%;
  padding: 2.5% 0;
  border-radius: 6px;
  border: 1px solid #CAD0DB;
  text-indent: 10px;
  margin-top: 3%;
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.inputRows {
  margin-bottom: 5%;
}

.USerLOginBox input::-moz-placeholder {
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.USerLOginBox input::placeholder {
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.USerLOginBox button {
  width: 85%;
  border-radius: 10px;
  text-transform: capitalize;
  background: #16469D;
  margin: 5% 0;
}

.USerLOginBox a {
  text-align: end !important;
  color: #16469D;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  margin-right: 8%;
}

.USerLOginBox h5 {
  text-align: start !important;
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.USerLOginBox2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  background-color: #F6FAFF;
  height: 100vh;
}

.USerLOginFOrm input:focus {
  border: 1.5px solid #000 !important;
}

.USerBoxSTart2 {
  width: 50%;
}

.USerBoxSTart2 .USerMEdiaBox2 {
  width: 100% !important;
}

.USerMEdiaBox2 img {
  border-radius: 2%;
  width: 100%;
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.USerBOxInfo {
  width: 100 !important;
  text-align: center;
  margin-top: 10%;
}

.USerBOxInfo h4 {
  width: 100 !important;
  color: #2B2727;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3%;
}

.USerBOxInfo h6 {
  width: 100 !important;
  color: #606778;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.USerBoxSTart2 ul .slick-active {
  width: 40px !important;
}

.USerBoxSTart2 ul .slick-active button {
  width: 40px !important;
}

.USerBoxSTart2 .slick-dots li button:before {
  width: 15px !important;
  content: "";
  background-color: gray;
  height: 5px;
  border-radius: 6px;
}

.USerBoxSTart2 ul .slick-active button::before {
  width: 40px !important;
  content: "";
  background-color: black !important;
  height: 5px;
}

@media (max-width: 1024px) {
  .USerBOxInfo h4 {
    font-size: 22px;
  }
  .USerBOxInfo h6 {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .USerBOxInfo h4 {
    font-size: 20px;
  }
  .USerBOxInfo h6 {
    font-size: 17px;
  }
}
@media (max-width: 620px) {
  .USerLOginBox2 {
    display: none;
  }
  .USerLOginBox {
    width: 100%;
  }
}
@media (max-width: 540px) {
  .USerLOginFOrm {
    width: 80% !important;
  }
  .USerLOginFOrm h3 {
    font-size: 22px;
  }
}
@media (max-width: 415px) {
  .USerLOginBox {
    width: 100% !important;
  }
  .USerLOginBox input {
    width: 100%;
    padding: 12px 0 !important;
    font-size: 14px !important;
  }
  .USerLOginBox .USerLOginFOrm {
    width: 100%;
  }
  .USerLOginFOrm h3 {
    font-size: 20px;
  }
  .USerLOginFOrm label {
    display: block;
  }
  .USerLOginBox button {
    width: 100%;
  }
  .USerLOginBox h5 {
    font-size: 14px;
  }
}/*# sourceMappingURL=NewLogin.css.map */