.partner-benefits {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #F7FAFF;
  margin-bottom: 2%;
}

.PartnerStart {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PartnerContent {
  width: 45%;
}

.partnerImg {
  width: 45%;
  padding: 2%;
  box-sizing: border-box !important;
}

.PartnerContent h6 {
  width: 80%;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #585858;
  padding-bottom: 5%;
}

.partnerImg h6 {
  display: none;
}

.partner-benefits1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #585858;
}

.box2 {
  display: flex !important;
  align-items: center;
  gap: 20px;
  padding: 2% 0;
  border-bottom: 1px solid #16469D;
}

.partnerImg img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 1280px) {
  .PartnerContent h6 {
    width: 90%;
    font-size: 25px;
  }
}
@media (max-width: 1024px) {
  .PartnerContent h6 {
    width: 90%;
    font-size: 25px;
  }
  .PartnerContent {
    width: 48%;
  }
  .partnerImg {
    width: 48%;
    padding: 1%;
    box-sizing: border-box !important;
  }
}
@media (max-width: 912px) {
  .PartnerContent h6 {
    width: 100%;
    font-size: 20px;
  }
  .PartnerContent {
    width: 48%;
  }
  .partnerImg {
    width: 48%;
    padding: 0;
    box-sizing: border-box !important;
  }
  .partner-benefits1 {
    font-size: 15px;
  }
}
@media (max-width: 820px) {
  .PartnerStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
  .PartnerContent {
    width: 100%;
  }
  .partnerImg {
    width: 100%;
    padding: 0;
    box-sizing: border-box !important;
  }
}
@media (max-width: 540px) {
  .PartnerContent h6 {
    width: 100%;
    font-size: 20px;
  }
  .partner-benefits1 {
    font-size: 12px;
  }
}
@media (max-width: 415px) {
  .PartnerStart {
    display: flex;
    flex-direction: column-reverse;
  }
  .PartnerContent h6 {
    display: none;
    width: 100%;
    font-size: 20px;
    padding-top: 5%;
  }
  .partnerImg h6 {
    display: block;
    width: 100%;
    font-size: 20px;
    padding-top: 5%;
    font-weight: 700;
    color: #585858;
    padding-bottom: 5%;
  }
  .partner-benefits1 {
    font-size: 12px;
  }
  .box2 {
    border-bottom: 0.5px solid rgb(193, 192, 192);
    padding: 5% 0;
  }
  .partner-benefits {
    background-color: #F7FAFF;
    margin-bottom: 10%;
  }
  .PartnerContent {
    padding-bottom: 10%;
  }
}/*# sourceMappingURL=PartnerBenefits.css.map */