
.ErrMsg{    
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #111;
}



.ErrorBtn{
    
    background-color: #F5AC38;
    font-size: 15px;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    text-transform: none;  
    width: 105px;
 
}

.ErrorBtn:hover{
    background-color: #F5AC38;
    font-weight: 500;
   
}




