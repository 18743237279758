
#custom .slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

}

#custom .slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
#custom .slick-list:focus
{
    outline: none;
}
#custom .slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}


#custom .slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

#custom .slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
#custom .slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
#custom .slick-track:after
{
    clear: both;
}
#custom .slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    
    height: 100%;
    min-height: 1px;
}
/* ----------- */
#custom .slick-slide > div {
    margin-left:1%;
    margin-right:10%;
    
  }
  /* ------------------------- */
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    
    height: auto;
    
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


#custom .slick-slide > div{
    margin: 0 25px;
  
}
#custom .slick-slide > div >div{
    padding: 10px 0px;
  
}
#custom .slick-slide > div >div {
    background: #dce6fe;
    color: #16469D;
    transition: 0.4s ease-in-out;
    height: 250px;

  }
  

#custom .slick-center > div >div{
    background: #16469D;
    color: #FFFFFF;
    transition: 0.4s ease-in-out;
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    transform: scale(1.13);


   

  }

#custom .slick-list {
    padding: 20px 50px !important;
  }  

