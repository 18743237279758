.contactUsBannerMain
{
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
}

.contactUsBanner {
    display: flex;
    justify-content: space-evenly;
    // align-items: flex-end;
    // justify-content: flex-end;
    width: 100%;
    height: 40vh;
    background-image: url("https://res.cloudinary.com/dxluokypg/image/upload/v1683263931/Contact-us-_e5spmc.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-right: 15%;

}

.TextInput12345 {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    // box-shadow: 1px 1px 10px 1px black;
    font-size: 18px;
    font-weight: 500;
    resize: none;
    text-indent: 10px;
    padding: 1% 0;
    border-radius: 5px;

    border: 1px solid rgb(196, 196, 196);
}

.TextInput12345:focus {

    border: 2px solid #16469D !important;

}

.TextInput12345::placeholder {
    color: #7d7d7d;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

}

.contactUsBannerForm {
    width: 35%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    background-color: #F1F1F1;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    padding: 3%;
    margin: 5% 0% 8%;
}


.SubmitBtn:hover {
    background-color: #2458B7 !important;
}

.SubmitBtn:focus {
    background-color: #4a4a4b !important;
    display: block;
}


.ContactFormStart
{
    display: flex;
   
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ContactFormStart > div
{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1300px;
    padding: 0 10px;
    gap: 25px;
    justify-content: center;
    // align-items: center;
    flex-wrap: wrap;
    height: 100%;
}


.ContactBox1 {
    display: flex;
    /* width: 50%; */
    flex: 1 0 300px;
    flex-direction: column;
    gap: 20px;
    background-color: #F6FAFF;
    padding: 2%;
  }
  
  .ContactBox1 > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  
  .FormInput {
    display: flex;
    flex-direction: column;
    flex: 1 0 300px;
    height: 100%;
    gap: 20px;
  }


@media screen and (max-width:768px) {
    .ContactFormStart > div
{
   
   flex-direction: column-reverse;
    
}
    
}

// @media(max-width: 500px) {
//     .contactUsBanner {
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         padding: 0%;
//     }

//     .contactUsBannerForm {
//         width: 40%;
//     }
// }

// @media(max-width: 800px) {
//     .contactUsBanner {
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         padding: 0%;
//     }

//     .contactUsBannerForm {
//         width: 60%;
//     }
// }

// @media(max-width: 1000px) {
//     .contactUsBannerForm {
//         width: 80%;
//     }
// }

// @media(max-width:768px) {
//     .contactUsBanner1 h3 {
//         font-size: 30px;
//         width: 80%;
//     }
// }

// @media(max-width:540px) {
//     .contactUsBanner1 h3 {
//         font-size: 25px;
//         width: 80%;
//     }

//     .ContactBox {
//         display: flex;
//         width: 100%;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column-reverse !important;
//     }

//     .FormInput label {
//         font-size: 15px;
//     }
// }

// @media(max-width:415px) {
//     .contactUsBanner1 h3 {
//         font-size: 20px;
//         width: 80%;
//     }

//     .ContactFormStart {
//         width: 100%;
//     }

//     .ContactBox {
//         width: 100%;
//         margin-bottom: 4%;
//     }
    
//     .ConactFormCard {
//         padding: 0 !important;
//     }
    
//     .ContactBox1 {
//         width: 100% !important;
//         margin: 0 !important;
//         padding: 0 !important;
//     }
    
//     .ContactBorder-Box {
//         margin: 0 !important;
//     }
    
//     .FormInput {
//         width: 100%;
//         margin-bottom: 4%;
//     }

//     .FormControl label {
//         padding: 0 !important;
//     }
//     .contactUsBanner {
//         height: 25vh !important;
//     }


//     .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
//         padding: 11.5px 0;
//         display: flex;
//         align-items: center;
//         text-indent: 10px;
//     }

//     .FormInput label {
//         font-size: 12px;
//     }
//     ::placeholder{
//         font-size: 12px !important;
//         color: '#232536';
//         font-weight: 300;
//     }

//     .TextInput12345::placeholder{
//         font-size: 12px;

//     }
//     .css-1l7svqh-MuiTypography-root{
//         font-size: 15px;
//     }

// }

// @media(max-width:360px) {
//     .contactUsBanner1 h3 {
//         font-size: 20px;
//         width: 90%;
//     }

//     .contactUsBanner {
//         height: 30vh;
//     }

//     .FormInput {
//         width: 100%;
//     }
//     .TextInput12345::placeholder{
//         font-size: 12px;
//     }

//     ::placeholder{
//         font-size: 12px !important;
//     }
//     .FormControl label {
//         font-size: 5px !important;
//     }
// }