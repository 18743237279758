.dot-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot {
  background-color: #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin: 0 3px;
  animation: bounce 0.6s infinite alternate;
}

@keyframes bounce {
  to {
    transform: translateY(-10px);
  }
}

