.UloginMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.UloginStart {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.UloginBox {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}



.UloginForm {
    width: 70%;
    display: flex;
    flex-direction: column;
}
.UloginForm label{
    color: #606778;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}


.box1 {
    margin-bottom: 5%;
}

.box1 h1 {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    padding: 0 !important;
    padding-bottom: 1% !important;
}

.box1 h2 {
    text-align: start !important;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 !important;
}

.box1 h2 span {
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.box2 h1 {
    color: #7B8499;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.box2 h1 span {
    color: #12141A;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 145.455% */
    text-decoration-line: underline;
}

.box2 h2 {
    color: #7B8499;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
.box2 h2 span{
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: underline;
}



.UloginBox input {
    width: 100%;
    padding: 2.5% 0;
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    text-indent: 10px;
    // margin-bottom: 5% !important;
    color: #7B8499;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.UloginBox input::placeholder {
    color: #7B8499;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.UloginBox button {
    width: 40%;
    border-radius: 10px;
    text-transform: capitalize;
    background: #16469D;
    margin: 5% 0;

}

.UloginBox a {
    text-align: end !important;
    color: #16469D;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* padding-bottom: 8%; */

}

.UloginBox h5 {
    text-align: start !important;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
}

.Uloginimage h4 {
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
}

.Uloginimage h6 {
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    text-decoration: none !important;
}

.UloginBox h5 span {
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.UloginBox .Uloginimage {
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}
.UloginBox2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-color: #F6FAFF;
    height: 100vh;

}
.UloginForm input:focus {
    border: 1.5px solid #000 !important;
}

.BoxStart2 {
    width: 50%;
}


.BoxStart2 .MediaBox2 {
    width: 100% !important;

}

.MediaBox2 img {
    border-radius: 2%;
    width: 100%;
    object-fit: fill !important;

}
.BoxInfo {
    width: 100 !important;
    text-align: center;
    margin-top: 10%;
}

.BoxInfo h4 {
    width: 100 !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.BoxInfo h6 {
    width: 100 !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}