.OurProjMain
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
}

.OurProjectCardStart
{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

@media screen and (max-width : 768px) {
    .OurProjectCardStart
{
    width: 100%;
    padding: 10px;
   
}
}
.projectCard
{
    flex: 1 0 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    height: auto;
    padding: 20px 20px;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    // max-width: 25vw;
    // min-width: 300px;
    max-width: 400px;
}

@media screen and (max-width:768px) {
    .projectCard
{
    max-width: none;
}

}
.projectCard:hover
{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.cardImg
{
    display: flex;
    height: 50vh;
}
.cardImg > img
{
width: 100%;
object-fit: cover;
}

.cardContent
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.cardContent > h2
{
    font-size: 20px;
    line-height: 31.38px;
    font-weight: 700;
    color: #1c2752;
    text-align: center;
   
}
.cardContent > p
{
    font-size: 17px;
    line-height: 21.78px;
    font-weight: 400;
    color: #7e7e7e;
    text-align: center;
}

// .project_Head {
//     font-size: 20px;
//     line-height: 31.38px;
//     font-weight: 700;
//     color: #1c2752;
//     text-align: center;
// }

// .project_text {
//     font-size: 17px;
//     line-height: 21.78px;
//     font-weight: 400;
//     color: #7e7e7e;
//     text-align: center;
// }
