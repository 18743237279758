:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
  }

.PertnerBGMainStart{
    display: flex;
    width:100%;
    height: 40vh;
    background-image: url("https://res.cloudinary.com/dxluokypg/image/upload/v1683263932/Join-us-23_zmjmpw.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
    align-items: center;
    box-sizing: border-box;
}
.PertnerBGStart{
    width:40%;
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    box-sizing: border-box;

}
.PertnerBGStart h4{
    font-weight: 700;
    font-size: 40px;
    line-height: 42px;
    color: var(--wt);
}


@media(max-width:1300px){
    .PertnerBGStart{
        width:50%;
        display: flex;
        flex-direction: column;
        padding-left: 4%;
        box-sizing: border-box;
    
    }
    .PertnerBGStart h4{
        font-weight: 700;
        font-size: 35px;
        color: var(--wt);
    }
}
@media(max-width:912px){
    .PertnerBGStart{
        width:50%;    
    }
    .PertnerBGStart h4{
        font-size: 30px;
    }
}
@media(max-width:768px){
    .PertnerBGStart{
        width:60%;    
    }
    .PertnerBGStart h4{
        font-size: 25px;
    }
}
@media(max-width:540px){
    .PertnerBGStart{
        width:70%;    
    }
    .PertnerBGStart h4{
        font-size: 20px;
    }
}
@media(max-width:415px){
    .PertnerBGStart{
        width:80%;    
    }
    .PertnerBGStart h4{
        font-size: 20px;
        line-height: 30px;
    }
    .PertnerBGMainStart{
        height: 30vh;
    }
    .PertnerBGMainStart{
        height: 25vh;
    }
}
