.findyouranswers {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 10%;
    // padding: 0 10%;
    // box-sizing: border-box;
}

.findyouranswersStart {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

}

.mobileview {
    flex: 1 0 50%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    gap: 20px;
    // height: 100%;
}

.AnsContact {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 700;
    border: 1px solid #16469D;
    border-radius: 6px;
    background-color: #FDFDFD;
    padding: 5% 5% 5% 5%;
    width: 80%;
    // margin-top: 5%;
    gap: 10px;
}


.AccordianStart {
    flex: 1 0 50%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;


}

.accordion
{
    display: flex;
    flex-direction: column;

}

.acordRow
{
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid #16469D;
    display: flex;
}
.accordhead
{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 15px;
    height: 70px;
    font-weight: 500;
    align-items: center;
    cursor: pointer;

}

.accordBody
{
   
  
    padding: 10px;
    font-size: 15px;
   font-weight: 400;
}

@media screen and (max-width: 900px) {
  
    .AnsContact {
      
        width: 100%;
        
    }
    
}

// .contbtnStart .contact_btn {
//     width: 100%;
//     padding: 5% 0;
// }

// .contbtnStart .accBtn {
//     width: 40%;
//     padding: 1% 0;
// }

// .accordion {
//     box-sizing: border-box;
//     width: 100%;
// }

// .item {
//     margin-bottom: 5px;
//     background-color: transparent;
//     padding-left: 20px;
//     padding-right: 20px;
//     margin-top: 1%;
//     border-bottom: 1px solid #16469D !important;
// }

// .drop {
//     font-size: 15px;
//     background: #16469D;
//     background-clip: text;
//     -webkit-text-fill-color: transparent;

// }

// .answer {
//     color: rgba(88, 88, 88, 1);
//     font-size: 12px;
//     line-height: 21px;
//     font-weight: 400;
//     color: #585858;

// }

// .title {
//     color: #585858;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     cursor: pointer;
//     font-size: 15px;
//     font-weight: 500;
//     line-height: 23px;
//     height: 53px;

// }

// .title span {
//     color: #585858;
//     font-size: 15px;
//     font-weight: 400;
//     line-height: 21px;
// }

// .content {
//     color: rgba(88, 88, 88, 1);
//     max-height: 0;
//     overflow: hidden;
//     transition: all 0.5s cubic-bezier(0, 1, 0, 1);

// }

// .show {

//     padding-bottom: 10px;
//     max-height: 9999;
//     font-size: 15px;
//     color: rgba(88, 88, 88, 1);
//     font-weight: 400;

// }


// @media (max-width: 900px) {
//     .mobileview h2 {
//         width: 90%;
//         font-size: 25px;
//     }

//     .mobileview h6 {
//         font-size: 13px;
//     }

//     .mobileview {
//         width: 100%;
//     }

//     .findyouranswersStart {
//         width: 85%;
//     }

//     .contact_btn {
//         font-size: 12px;
//         padding: 4%;
//     }
// }

// @media (max-width: 768px) {
//     .findyouranswers {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 100%;
//     }

//     .findyouranswersStart {
//         display: flex;
//         width: 90%;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//         padding: 0;
//     }

//     .mobileview {
//         width: 90%;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//     }

//     .mobileview h2 {
//         margin-top: 2%;
//         width: 100%;
//         display: flex;
//         justify-content: center;
//     }

//     .AccBtn {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }

//     .accBtn {
//         width: 100%;
//         margin-top: 5%;
//     }
// }

// @media (max-width: 456px) {
//     .findyouranswersStart {
//         width: 100%;
//     }

//     .accBtn {
//         width: 35%;
//         font-size: 15px;
//     }

//     .mobileview h2 {
//         margin-top: 15%;
//         font-size: 18px;

//     }

//     .AnsContact {
//         width: 90%;
//     }

//     .title p {
//         font-size: 12px;
//     }

//     .item {
//         padding-left: 0 !important;
//         padding-right: 0 !important;

//     }

//     .AccordianStart {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         margin-top: 0 !important;

//     }

//     .accordion {
//         display: flex;
//         flex-direction: column;
//         width: 80%;

//     }

//     .title {
//         height: 45px;
//     }
// }


// @media (max-width: 415px) {
//     .findyouranswersStart {
//         width: 100%;
//         display: flex;
//         flex-direction: column-reverse;
//     }
//    .AccBtn{
//     display: flex;
//     justify-content: flex-start !important;
//     align-items: flex-start;
//     margin-bottom: 5%;
//    }
//     .accBtn {
//         width: 35%;
//         font-size: 15px;
//     }
//     .mobileview{
//         width: 90%;
//     }


//     .mobileview h2 {
//         margin-top: 15%;
//         font-size: 18px;
//         display: none;
//     }

//     .AnsContact {
//         width: 100%;
//         margin-top: 10%;
//         margin-bottom: 10%;
//     }

//     .title p {
//         font-size: 12px;
//     }

//     .item {
//         padding-left: 0 !important;
//         padding-right: 0 !important;

//     }

//     .AccordianStart {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         margin-top: 0 !important;
//     }

//     .accordion {
//         display: flex;
//         flex-direction: column;
//         width: 90%;

//     }

//     .title {
//         height: 45px;
//     }
//     .accordion h2{
//         font-size: 20px;
//         font-weight: 600;
//         width: 100%;
//     }
// }