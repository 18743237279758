.AboutUsPageNewletterMain {
    display: flex;
    width: 100%;
    height: 300px;
    background-color: #F6FAFF;
    align-items: center;
    justify-content: center;

}

.AboutUsPageNewletter {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
}

.AboutUsPageNewletterBox1 {
    display: flex;
    width: 50%;
}

.AboutUsPageNewletterBox2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 73px;
    align-items: center;
    justify-content: center;
}

.AboutUSSubscribe {
    width: 70%;
    height: 50px;
    font-size: 18px;
    border: 2px solid #16469d;
    background: #ffffff;
    color: #000;
    text-indent: 20px;
    border-radius: 4px 0px 0px 4px;
}

.AboutUSSubscribe::placeholder {
    font-size: 16px;
}

.AboutUSSubscribe:focus {
    border: 2px solid #2458b7 !important;
    outline-offset: 0px;
}

.AboutUsPageNewletterBTN:focus {
    background-color: #16469d;

}

.AboutUsPageNewletterBTN:hover {
    background-color: #2458b7;
}


// -----------------------------------------------------------------------
@media(max-width:1034px) {
    .AboutUsPageNewletterBox1 h5 {
        font-size: 28px;
    }

    .AboutUsPageNewletterBox1 h6 {
        font-size: 20px;
    }
}

@media(max-width:'890px') {
    .AboutUsPageNewletterMain {
        width: 100%;
        display: flex;

    }

    .AboutUsPageNewletter {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .AboutUsPageNewletterBox1 {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 4%;
    }

    .AboutUsPageNewletterBox2 {
        display: flex;
        width: 100%;
    }
}

@media(max-width:605px) {
    .AboutUsPageNewletterBox1 h4 {
        font-size: 25px;
    }

    .AboutUsPageNewletterBox1 h6 {
        font-size: 15px;
    }

    .AboutUsPageNewletterBox2 {
        height: 50px;
    }
    .AboutUSSubscribe {
        font-size: 18px;
        text-indent: 10px;
    }
    .AboutUsPageNewletterBTN{
        font-size: 18px;
    }

}

@media(max-width:476px) {
    .AboutUSPageBar {
        width: 100%;
    }

    .AboutUsPageNewletterBox2 {
        height: 40px;
    }
}

@media(max-width:415px) {
    .AboutUsPageNewletterMain {
        height:200px;
    }
    .AboutUsPageNewletterBox1 h5 {
        font-size: 20px;
    }

    .AboutUsPageNewletterBox1 h6 {
        font-size: 15px;
        padding-bottom: 2%;
    }
    .AboutUSSubscribe {
        font-size: 15px;
        text-indent: 10px;
    }
    .AboutUsPageNewletterBTN{
        font-size: 15px;
    }
    .AboutUsPageNewletterBox1 {
        display: flex;
        width: 100%;
        text-align:center;
    }
    
}

@media(max-width:281px) {
    .AboutUsPageNewletterBox1 h5 {
        font-size: 15px;
    }

    .AboutUsPageNewletterBox1 h6 {
        font-size: 12px;
    }

    .AboutUSSubscribe {
        text-indent: 5px;
        font-size: 12px;
    }
    .AboutUSSubscribe::placeholder {
        font-size: 12px;
    }
    .AboutUsPageNewletterBTN{
        font-size: 10px;
    }

}