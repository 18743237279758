
.AboutUsPageMainBg{
    background-image:  url('https://res.cloudinary.com/dxluokypg/image/upload/v1683263875/abouts-banner-s_ykgb09.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    width: 100%;
    align-items: center;
    height: 40vh;
    box-sizing: border-box;
    margin-bottom: 5%;

}
.AboutUsPageBG{
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 5%;
}

@media(max-width:415px){
    .AboutUsPageMainBg{
        height: 25vh;
    }
    .AboutUsPageBG h6{
        font-size: 20px;
    }
}