.AboutUsPageContent {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-items: stretch;
  margin-bottom: 4%;
}

.AboutUsPageContentMain {
  display: flex;
  width: 90%;
}

.AboutUsPageContentBGHide {
  display: none;
}

.AboutUsPageContentInfo {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.AboutUsPageContentBG {
  width: 50%;
  display: flex;
}

.AboutUsPageContentBackground {
  width: 100%;
  display: flex;
  background-image: url("https://res.cloudinary.com/dxluokypg/image/upload/v1683103479/pexels-anamul-rezwan-1216589_1_y5ekfi.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.TickBox {
  width: 40px;
  height: 40px;
  background-color: #16469d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4%;
}

.TickBox2 {
  width: 50px;
  height: 50px;
  color: #F5AC38;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContentbuttonBox {
  width: 100%;
  display: flex;
}

.ContentBtnStart {
  width: 25%;
}

@media (max-width: 1440px) {
  .AboutUsPageKjss {
    width: 50%;
  }
}
@media (max-width: 1287px) {
  .AboutUsPageKjss {
    width: 65%;
  }
  .ContentbuttonBox {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ContentBtnStart {
    width: 100%;
  }
  .MyContentBtn {
    width: 40%;
    margin-bottom: 4%;
    padding: 2% 0;
  }
  .MyContentBtnInfo {
    margin-left: 0 !important;
    margin-top: 5%;
  }
}
@media (max-width: 990px) {
  .AboutUsPageContentMain {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .AboutUsPageContentBG {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .AboutUsPageContentBackground {
    display: flex;
    width: 80%;
    height: 500px;
  }
  .AboutUsPageContentInfo {
    display: flex;
    width: 100%;
    padding-left: 5%;
  }
  .AboutUsPageContentIcons {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
  }
  .AboutUSPageBar {
    margin-bottom: 1%;
  }
  .AboutUsPageKjss {
    width: 45%;
  }
  .MyContentBtn {
    width: 30%;
    margin-bottom: 0%;
    padding: 2% 0;
  }
}
@media (max-width: 980px) {
  .AboutUsPageContentInfo {
    display: flex;
    width: 100%;
  }
  .ContentbuttonBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
  }
  .MyContentBtn {
    width: 35%;
    padding: 1.5% 0;
  }
}
@media (max-width: 724px) {
  .AboutUsPageKjss {
    width: 55%;
  }
  .AboutUsPageContentBackground {
    height: 400px;
  }
}
@media (max-width: 627px) {
  .AboutUsPageContentBackground {
    display: flex;
    width: 90%;
  }
  .AboutUsPageKjss {
    width: 80%;
  }
}
@media (max-width: 605px) {
  .AboutUsPageContentBackground {
    height: 300px;
  }
  .AboutUsPageKjss {
    width: 80%;
    font-size: 22px;
  }
  .AboutUsContentPara h6 {
    font-size: 15px;
  }
  .AboutUSheader {
    font-size: 15px;
  }
  .MyContentBtn {
    width: 50%;
    font-size: 18px;
    margin-bottom: 5%;
  }
  .TickBox {
    width: 30px;
    height: 30px;
    font-size: 10px;
  }
  .MyContentBtnInfo h6 {
    font-size: 18px;
  }
  .MyContentBtnInfo h5 {
    font-size: 18px;
  }
}
@media (max-width: 476px) {
  .AboutUSPageBar {
    width: 100%;
  }
  .AboutUsPageKjss {
    width: 80%;
    font-size: 20px;
    margin-bottom: 2%;
  }
}
@media (max-width: 415px) {
  .AboutUsPageKjss {
    font-size: 15px;
  }
  .AboutUsContentPara h6 {
    font-size: 12px;
  }
  .MyContentBtn {
    padding: 2.5% 0;
    font-size: 12px;
    width: 35%;
  }
  .AboutUsPageContentBackground {
    height: 250px;
    border-radius: 4px;
  }
  .AboutUsPageContentBGHide {
    margin-bottom: 5%;
    display: block;
  }
  .AboutUsPageContentBG {
    display: none;
  }
  .TickBox {
    width: 20px;
    height: 20px;
  }
  .Tickmark {
    font-size: 15px;
  }
  .ContentbuttonBox {
    flex-direction: column-reverse;
  }
  .MyContentBtnInfo {
    margin-top: 0 !important;
    margin-bottom: 10%;
  }
  .AboutUsPageContentIcons {
    padding: 0;
    margin-bottom: 0 !important;
  }
  .ContentBtnStart {
    width: 100%;
    font-size: 20px;
    padding: 0 !important;
    margin-bottom: 5%;
  }
  .AboutUsPageContentInfo {
    margin-top: 5%;
  }
}
@media (max-width: 281px) {
  .AboutUsPageKjss {
    width: 90%;
    font-size: 15px;
  }
  .AboutUsContentPara h6 {
    font-size: 13px;
  }
  .AboutUSheader {
    font-size: 13px;
  }
  .MyContentBtn {
    width: 80%;
    font-size: 15px;
    margin-bottom: 5%;
  }
  .TickBox {
    width: 18px;
    height: 18px;
  }
  .MyContentBtnInfo h6 {
    font-size: 13px;
  }
  .MyContentBtnInfo h5 {
    font-size: 13px;
  }
  .AboutUsPageContentBG {
    width: 100%;
  }
  .AboutUsPageContentBackground {
    height: 200px;
  }
}/*# sourceMappingURL=AboutUsPageContent.css.map */