.newpasswordMain {
    display: flex;
    width: 100%;
}

.newPasswordStart {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.newPasswordbOX {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.newOtpBox2 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

// .forgotpassword {
//     display: flex;
//     justify-content: space-evenly;
//     align-items: center;
//     width: 100%;
//     background: linear-gradient(90deg, #2680BD 7.58%, rgba(56, 184, 231, 0.794) 50.49%, rgba(81, 174, 245, 0.56) 92.52%);
//     mix-blend-mode: normal;
//     background-repeat: no-repeat;
//     background-size: cover;
//     padding-top: 8%;
//     padding-bottom: 8%;
//     height: 100vh;
// }

.newpassword-form {
    width: 50%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 4%;

}

.newpassword-form h3 {
    color: #000;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 4%;
}

.newpassword-form h5 {
    color: #302D2D;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4%;
}

.newPasswordbOX input {
    padding: 3% 0;
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    text-indent: 10px;
    margin: 0;
}

.newPasswordbOX input::placeholder {
    color: #7B8499;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.newpassword-form input:focus {
    border: 1.5px solid #000 !important;
}


.newPasswordbOX button {
    border-radius: 10px;
    width: 100%;
    background: #16469D;
    text-transform: capitalize;
    margin-bottom: 5%;
}


.newPassBack {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none ;
    
}

.newPassBack h6 {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none ;
}
.nbackItem {
    width: 20px;
    height: 20px;
    color: #000;
}

// ////// OTP INPUT DESIGN ////////////////






.newOtpBox2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-color: #F6FAFF;
    height: 100vh;
}

.newOtpSTart2 {
    width: 50%;
}


.newOtpSTart2 .NewOTPMEdiaBox2 {
    width: 100% !important;

}

.NewOTPMEdiaBox2 img {
    border-radius: 2%;
    width: 100%;
    object-fit: fill !important;
}
.newOtpSTart2 ul .slick-active {
    width: 40px !important;
}

.newOtpSTart2 ul .slick-active button {
    width: 40px !important;
}

.newOtpSTart2 .slick-dots li button:before {
    width: 15px !important;
    content: '';
    background-color: gray;
    height: 5px;
    border-radius: 6px;
}

.newOtpSTart2 ul .slick-active button::before {
    width: 40px !important;
    content: '';
    background-color: black !important;
    height: 5px;

}

.newOTPInfo {
    width: 100 ;
    text-align: center;
    margin-top: 10%;
}

.newOTPInfo h4 {
    width: 100 !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.newOTPInfo h6 {
    width: 100 !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




@media(max-width:1024px) {
    .newpassword-form {
        width: 80%;
    }

}

@media(max-width:768px) {
    .newOTPInfo h4 {
        font-size: 22px;
    }
    
    .newOTPInfo h6 {
        font-size: 17px;
    }
}

@media(max-width:620px) {
    .newOtpBox2 {
        display: none;
    }
    
    .newPasswordbOX{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

}

@media(max-width:415px) {
    .newOtpBox2 {
        display: none;
    }
    .newpassword-form h3 {
        text-align: left;
    }

    .newPasswordbOX label {
        font-size: 15px !important;
        padding-right: 0;
    }
}

