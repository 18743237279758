.partner-opp123 {
  display: flex;
  width: 100%;
  padding-top: 4%;
  justify-content: space-between;
  flex-direction: column;
  background-image: url("https://res.cloudinary.com/dxluokypg/image/upload/v1680939731/Indian_Tricolor_Flag_Png_Free_Download_-_1280x915_1_qxn0tp.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.partner-opp-box123 {
  display: flex;
  justify-content: space-evenly;
  width: 65%;
  gap: 3%;
  margin-left: 5%;
  margin-bottom: 5%;
}

.partnerName {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
}

.partnerDes {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #585858;
}

.dlbrochure {
  font-weight: 400;
  font-size: 15px;
  line-height: 12px;
  color: rgb(245, 172, 56);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4%;
}

.PartnerSquare:hover {
  transform: scale(1.1);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

@media (max-width: 1280px) {
  .partner-opp-box123 {
    width: 80%;
  }
}
@media (max-width: 998px) {
  .partner-opp-box123 {
    width: 90%;
  }
  .PartnerSquare {
    padding: 2% !important;
  }
}
@media (max-width: 768px) {
  .partner-opp123 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .partner-opp-box123 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .PartnerSquare {
    width: 400px;
    padding: 4% !important;
  }
}
@media (max-width: 600px) {
  .partner-opp123 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .partner-opp-box123 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .globalPartner {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    width: 100%;
    color: rgb(88, 88, 88);
  }
  .partnerHead1 {
    font-weight: 700;
  }
  .partnerName {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: rgb(88, 88, 88);
  }
  .partnerDes {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgb(88, 88, 88);
  }
  .dlbrochure {
    display: flex;
    justify-content: right;
    text-align: right;
    width: 100%;
    padding-top: 0%;
  }
}
@media (max-width: 415px) {
  .partner-opp123 {
    margin-bottom: 20%;
  }
  .partnerName {
    font-size: 18px;
  }
  .partnerDes {
    font-size: 12px;
    margin-top: 4% !important;
  }
  .dlbrochure {
    display: flex;
    justify-content: flex-start;
    text-align: start;
    width: 100%;
    font-size: 12px;
    margin-top: 0 !important;
    gap: 2%;
  }
  .partnerHead1 {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    text-align: start;
    font-size: 20px;
    font-weight: 600;
    padding-left: 7%;
  }
  .PartnerSquare {
    width: 80%;
    border: 0.5px solid #a2a2a2;
  }
  .partner-opp-box123 {
    display: flex;
    width: 90%;
    margin-left: 0 !important;
    flex-direction: column;
    justify-content: flex-start;
  }
  .PartnerSquare h6 {
    margin-top: 20% !important;
  }
}/*# sourceMappingURL=PartnerOpp.css.map */