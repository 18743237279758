
#custom .slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
#custom .slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
#custom .slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
#custom .slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

#custom .slick-prev:before,
#custom .slick-next:before
{
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #585858;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#custom .slick-prev
{
    left: 25px;
    z-index: 2;
   
}
#custom [dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;

}
#custom .slick-prev:before
{
    content: '←';
    background-color: rgb(248, 248, 230);
    border-radius: 50%;

    padding: 10px;
}
#custom [dir='rtl'] .slick-prev:before
{
    content: '→';
    background-color: beige;
    border-radius: 50%;
    padding: 10px;
}

#custom .slick-next
{
    right:50px;
   

}
#custom [dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
#custom .slick-next:before
{
    content: '→';
    background-color: beige;
    border-radius: 50%;
    padding: 10px;
}
#custom [dir='rtl'] .slick-next:before
{
    content: '←';
    background-color: beige;
    border-radius: 50%;
    padding: 10px;
}

/* Dots */
#custom.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

#custom .slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
#custom.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
#custom .slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
#custom .slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
#custom .slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    
    opacity: 1;
}
#custom .slick-dots li button:before
{
    font-size: 6px;
    line-height: 20px;
    color: #585858;
    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;
    /* background-color:#585858;
    border-radius: 50%;
    border: 1px solid orange; */

    content: '•';
    text-align: center;

    opacity: .25;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#custom .slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
