.VendorMainvenDOR {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.VendorStartVenDOR {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.VendorBoxvender {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.VendOrFormvendOr {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.VendorBox1Vendor {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.VendorBox1Vendor h1 {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
}

.VendorBox3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
}

.VendorBox3 label {
    color: #606778;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-bottom: 1%;
}

.VendorBox3 textarea {
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    width: 100%;
    resize: none;
    padding: 2%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    width: 100%;
}

.VendorNumberBox {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.VendorNumberBox input {
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    padding: 2.5% 0;
    width: 80%;
    margin-bottom: 5%;
    text-indent: 10px;
}

.VendorNumberBox label {
    color: #606778;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-bottom: 1%;
}

.VendOrFormvendOr {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.VendorBoxvender input:focus {
    border: 1.5px solid #000 !important;
}

.VendorBoxvender textarea:focus {
    border: 1.5px solid #000 !important;
}

.countryinput input:focus {
    border: none !important;
}

.VendOrFormvendOr button {
    background-color: #16469d;
    color: #fff;
    text-transform: capitalize;
}
.VendOrFormvendOr button:hover {
    background-color: #16469d;
  
    text-transform: capitalize;
}

.countryinput {
    width: 80%;
    margin: 5% 0;
}

.textvendor {
    width: 100%;
    height: 20vh;
}
.VendorNumberBox textarea{
    width: 80%;
    height: 15vh;
    resize: none;
    border: 1px solid #CAD0DB;
    border-radius: 6px;
    padding: 2% ;
    margin-bottom: 3%;

}

/* -----------------------------secound tab---------------------------------------- */
.Vendorsecound {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-color: #F6FAFF;
    height: 100vh;

}

.VendorForm input:focus {
    border: 1.5px solid #000 !important;
}

.VendorBoxStart2 {
    width: 50%;
}


.VendorBoxStart2 .VendorMediaBox2 {
    width: 100% !important;

}

.VendorMediaBox2 img {
    border-radius: 2%;
    width: 100%;
    object-fit: fill !important;

}

.VendorUSerBOxInfo {
    width: 100% !important;
    text-align: center;
    margin-top: 10%;
}

.VendorUSerBOxInfo h4 {
    width: 100 !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.VendorUSerBOxInfo h6 {
    width: 100 !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.VendorBoxStart2 ul .slick-active {
    width: 40px !important;
}

.VendorBoxStart2 ul .slick-active button {
    width: 40px !important;
}

.VendorBoxStart2 .slick-dots li button:before {
    width: 15px !important;
    content: '';
    background-color: gray;
    height: 5px;
    border-radius: 6px;
}

.VendorBoxStart2 ul .slick-active button::before {
    width: 40px !important;
    content: '';
    background-color: black !important;
    height: 5px;

}

/* ----------------------drop down--------------- */
.selectwrapper {
    width: 100%;
    margin-bottom: 5%;
}

.selectdropdown {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
}

.selectdropdown:focus {
    border-color: #6c63ff;
    outline: none;
}

.selectoptions {
    display: none;
    position: absolute;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.selectoptions {
    padding: 20px !important;
    cursor: pointer;
}

.selectoptions:hover {
    background-color: #6c63ff;
    color: white;
}

/* -------------------------responsive------------------------ */


@media(max-width:768px) {
    .Vendorsecound {
        display: none;
    }

    .VendorBoxvender {
        display: flex;
        width: 100%;
    }


}

@media(max-width:415px) {

    .VendorBox1Vendor h1 {
        font-size: 20px;
    }

    .VendorBoxvender button {
        margin-top: 4%;
    }

    .VendorBoxvender {
        width: 85%;
    }

    .VendOrFormvendOr {
        width: 100%;
    }
    .VendorNumberBox input {
        padding: 4% 0;
    }
    .VendorNumberBox button {
       width: 40%;
    }
}


