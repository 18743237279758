.partner-logo {
    display: flex;
    width: 100%;
    justify-content: center;
}

.logoStart {
    display: flex;
    width: 95%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1%;
    padding: 2% 0;
}
.logoStartHide{
    display: none;
}

.logoStartHide .partner-logo-image0{
    width: 20%;
    height: 20vh;

}
.partner-logo-image {
    width: 8%;

}

.partner-logo-image2 {
    width: 8%;
    padding-top: 1%;
}



@media (max-width: 768px) {

    .partner-logo-image {
        width: 26%;
        padding-bottom: 2%;
    }

    .partner-logo-image2 {
        width: 25%;
        padding-bottom: 2%;
    }

}

@media (max-width: 540px) {
    .logoStart {
        display: flex;
        gap: 20px;
    }

    .partner-logo-image {
        width: 35%;
    }

    .partner-logo-image2 {
        width: 35%;

    }

}

@media (max-width: 415px) {
    .logoStart {
        display: none;
        gap: 20px;
    }
    .logoStartHide{
        display: block;
        width: 100%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .logoStartHide .partner-logo-image0{
        height: 5vh;

    
    }

}