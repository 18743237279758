.forgotpasswordOTPMain {
  display: flex;
  width: 100%;
}

.forgotpassOTPStart {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.forgotpassOTPbOX {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ForgotOTPLOginBox2 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.forgotpassword {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: linear-gradient(90deg, #2680BD 7.58%, rgba(56, 184, 231, 0.794) 50.49%, rgba(81, 174, 245, 0.56) 92.52%);
  mix-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 8%;
  padding-bottom: 8%;
  height: 100vh;
}

.forgotpassOTP-form {
  width: 50%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4%;
}

.forgotpassOTP-form h3 {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 4%;
}

.forgotpassOTP-form h5 {
  color: #302D2D;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4%;
}

.forgotOTPInput fieldset {
  border: none !important;
  border-radius: 0;
  border-bottom: 2px solid #d0d0d0 !important;
}

.forgotOTPInput input {
  color: #868686;
  font-size: 25px;
}

.forgotpassOTPbOX button {
  border-radius: 10px;
  width: 100%;
  background: #16469D;
  text-transform: capitalize;
  margin-bottom: 5%;
}

.forgetOTPBack {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

.forgetOTPBack h6 {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.OTPbackItem {
  width: 20px;
  height: 20px;
  color: #000;
}

.ForgotOTPcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forgototp-input {
  border: none;
  padding: 0px;
  width: 16.1ch;
  background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 2ch, transparent 0, transparent 3ch) 0 100%/14ch 2px no-repeat;
  font: 5ch "Droid Sans Mono", "Consolas", monospace;
  letter-spacing: 2ch;
  text-align: center;
  text-indent: -1;
  outline: none;
  cursor: text;
}

.ForgotOTPLOginBox2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  background-color: #F6FAFF;
  height: 100vh;
}

.ForgotOTPBoxSTart2 {
  width: 50%;
}

.ForgotOTPBoxSTart2 .ForgiotOTPMEdiaBox2 {
  width: 100% !important;
}

.ForgiotOTPMEdiaBox2 img {
  border-radius: 2%;
  width: 100%;
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.ForgotOTPBoxSTart2 ul .slick-active {
  width: 40px !important;
}

.ForgotOTPBoxSTart2 ul .slick-active button {
  width: 40px !important;
}

.ForgotOTPBoxSTart2 .slick-dots li button:before {
  width: 15px !important;
  content: "";
  background-color: gray;
  height: 5px;
  border-radius: 6px;
}

.ForgotOTPBoxSTart2 ul .slick-active button::before {
  width: 40px !important;
  content: "";
  background-color: black !important;
  height: 5px;
}

.ForgotttOTPBOxInfo {
  width: 100;
  text-align: center;
  margin-top: 10%;
}

.ForgotttOTPBOxInfo h4 {
  width: 100 !important;
  color: #2B2727;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ForgotttOTPBOxInfo h6 {
  width: 100 !important;
  color: #606778;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1024px) {
  .forgotpassOTP-form {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .ForgotttOTPBOxInfo h4 {
    font-size: 22px;
  }
  .ForgotttOTPBOxInfo h6 {
    font-size: 17px;
  }
}
@media (max-width: 620px) {
  .ForgotOTPLOginBox2 {
    display: none;
  }
  .forgotpassOTPbOX {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}/*# sourceMappingURL=ForgotPassOTP.css.map */