.FeedbackPageMain {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    max-width: 100%;
}

.FeedbackPageForm {
    border-radius: 1%;
}

.FeedbackPageBackground {
    background: (linear-gradient(50.74deg, rgba(0, 0, 0, 0.427) 17.36%, rgba(52, 40, 10, 0.455) 87.82%), url(https://res.cloudinary.com/dxluokypg/image/upload/v1679149726/FeedbackPageBG_cccpxe.svg));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    width: 100%;
    align-items: center;
    height: 315px;
    box-sizing: border-box;
    margin-bottom: 5%;
    // position: relative;

}

.FeedbackPageBG {
    display: flex;
    width: 35%;
    height: 100px;
    margin-left: 5%;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

}

.FeedbackPageInfo {
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: space-between;
    margin-bottom: 10%;
}

.FeedbackPagePics {
    display: flex;
    width: 30%;
    position: absolute;
    top: 40%;
    left: 10%;

}

.FeedbackPagePics img {
    display: flex;
    width: 100%;
    height: 500px;
}

.SubmitBtn {
    background-color: #2458B7;
}

.submit:hover {
    box-shadow: 1px 5px 7px 1px rgba(0, 0, 0, 0.2);
    background-color: #16469D;
}

.submit:focus {
    box-shadow: 1px 5px 7px 1px rgba(0, 0, 0, 0.2);
    background-color: rgba(72, 72, 72, 0.627);
}

.textarea {
    border-radius: 5%;
}

.MYinput1 {
    color: #585858;
}

// ----------------------------------------------------------
.FeedbackPageForm {
    display: flex;
    justify-content: center;
    width: 40%;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(91, 90, 90, 0.305);
    position: absolute;
    align-items: stretch;
    padding-top: 2%;
    padding-bottom: 2%;
    top: 25%;
    left: 55%;
    box-sizing: border-box;
}

.Feedform {
    display: flex;
    justify-content: center;
    width: 95%;
    box-sizing: border-box;
}

.Feedformcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.input-field {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;

}

.MYinput {
    height: 45px;
    width: 100%;
    border: 2px solid rgba(65, 64, 64, 0.645) !important;
    padding: 0 0 0 45px;
    background: #fff;
    text-indent: 8px;
    color: #000;
    font-size: 1.1rem;

}

.MYinput1 {
    height: 45px;
    width: 100%;
    border: 2px solid #5858586c !important;
    padding: 0 0 0 45px;
    background: #fff;
    text-indent: 8px;
    color: #585858;
    font-size: 1.1rem;
}

.TextInput {
    width: 100%;
    border: 2px solid #5858586c !important;
    background: #fff;
    padding: 10px 0 0 10px;
    color: #585858;
    resize: none;
    font-size: 1.1rem;
}

.MYinput1:focus {
    border: 2px solid #16469D !important;
    outline-offset: 0px;
}

.MYinput:focus {
    border: 2px solid #16469D !important;
    outline-offset: 0px;
}

.TextInput:focus {
    border: 2px solid #16469D !important;
    outline-offset: 0px;
}


.formIcon {
    position: absolute;
    top: 42%;
    left: 4%;
    color: rgba(65, 64, 64, 0.305);
}

.formIcon2 {
    position: absolute;
    top: 47%;
    left: 4%;
    color: rgba(65, 64, 64, 0.305);
}

::-webkit-input-placeholder {
    color: rgba(65, 64, 64, 0.305);
}

.submit {
    font-size: 20px;
    outline: none;
    border: none;
    height: 45px;
    width: 100%;
    background: #16469D;
    cursor: pointer;
    transition: 0.3s;
    text-transform: capitalize;

}

.submit:hover {
    box-shadow: 1px 5px 7px 1px rgba(0, 0, 0, 0.2);
}

.submit:focus {
    box-shadow: 1px 5px 7px 1px rgba(0, 0, 0, 0.2);
    background-color: rgba(72, 72, 72, 0.627);
}

// ---------------------------------------media------------------------------------------------------------------------------
@media(max-width:1320px) {
    .FeedbackPageBG {
        width: 90%;
    }

    .FeedbackPageBG h3 {
        font-size: 35px;
    }

    .FeedbackPageBG h6 {
        font-size: 20px;
    }
}

@media(max-width:912px) {
    .FeedbackPagePics {
        width: 50%;
        left: 4%;
        top: 40%;
    }

    .formIcon {
        font-size: 35px;
        top: 40%;
        left: 6%;
    }

    .formIcon2 {
        font-size: 30px;
        top: 43%;
        left: 7%;

    }
}

@media(max-width:900px) {
    .FeedbackPagePics {
        width: 40%;
    }

    .FeedbackPagePics img {
        display: flex;
        width: 100%;
    }
}

@media(max-width:'805px') {
    .FeedbackPageBG {
        width: 60%;
    }

    .FeedbackPageBG h3 {
        font-size: 40px;
    }

    .FeedbackPageBG h6 {
        font-size: 17px;
    }

    .FeedbackPagePics {
        display: flex;
        width: 80%;
    }

}


@media(max-width:800px) {
    .FeedbackPagePics {
        width: 85%;
        top: 50%;
        left: 5%;
    }

    .FeedbackPagePics img {
        display: flex;
        width: 100%;

    }

    .FeedbackPageForm {
        display: flex;
        width: 80%;
        top: 130%;
        left: 10%;
    }

    .MYinput {
        font-size: 0.9rem;
    }

    .MYinput1 {
        font-size: 0.9rem;
    }

    .submit {
        font-size: 17px;
        height: 40px;
    }

    .formIcon {
        font-size: 32px;
        top: 40%;
        left: 4%;
    }

    .formIcon2 {
        font-size: 28px;
        top: 43%;
        left: 4%;

    }
}

@media(max-width:769px) {
    .FeedbackPageForm {
        top: 95%;
    }

    .FeedbackPagePics {
        display: none;
    }

    .FeedbackPageInfo {
        display: flex;
        position: relative;
    }

    .FeedbackPageForm {
        width: 90%;
        position: absolute;
        left: 5%;
        top: -75%;
    }

    .FeedbackPageBackground {
        margin-bottom: 50%;

    }
}

@media(max-width:540px) {
    .FeedbackPageForm {
        top: 95%;
    }

    .FeedbackPageForm {
        position: absolute;
        left: 5%;
        top: -50%;
    }
}

@media(max-width:415px) {
    .FeedbackPageBackground{
        display: flex;
    align-items: center;
    }
    .FeedbackPageForm {
        top: 95%;
    }

    .FeedbackPageForm {
        position: absolute;
        left: 5%;
        top: -40%;
    }

    .FeedbackPageBackground {
        height: 25vh !important;
    }

    .FeedbackPageBG {
        width: 85%;
        display: flex;
        align-items:center;
        flex-direction: column;
    }

    .FeedbackPageBG h3 {
        padding-top: 10% !important;
        font-size: 20px;
        width: 100%;
        font-weight: 600;
    }

    .FeedbackPageBG h6 {
        font-size: 12px;
        width: 100%;
        font-weight: 500;
    }
}