.UloginMainFisrst {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.UloginvendoStart {
    display: flex;
    width: 100%;
}

.UloginBoxformform {
    width: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UloginFormstartst {
    width: 50% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vendorttrtrtr {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.UloginBox2Vendor {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-color: #F6FAFF;
    height: 100vh;

}

.UloginBoxformform input:focus {
    border: 1.5px solid #000 !important;
}

.UloginBoxformform textarea:focus {
    border: 1.5px solid #000 !important;
}


.UloginFormstartst {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    flex-direction: column;

}

.UloginFormstartst label {
    color: #606778;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}


.UloginFormstartst .box1 {
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.box3 select {
    width: 100%;
}

.UloginFormstartst .box1 h1 {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    padding: 0 !important;
}

.UloginFormstartst .box1 h2 {
    text-align: start !important;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 !important;
}

.UloginFormstartst .box1 h2 span {
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.box2 {
    display: block;
    width: 100% !important;
}

.box2 h1 {
    color: #7B8499;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: block;
}

.box2 h1 span {
    color: #12141A;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 145.455% */
    text-decoration-line: underline;
}
.css-sox5kk-MuiBackdrop-root {
    backdrop-filter: blur(0px);
    background-color: #454545 !important;
    opacity: 49% !important;
}
.box2 h2 {
    color: #7B8499;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
.lastButton{
    width: 100%;
    display: flex;
   justify-content: left;
    margin: 5% 0;
}
.lastButton button{
    width: 40%;
    height: 44px;
    border-radius: 10px;
    text-transform: capitalize;
    background-color: #16469D;

}

.box2 h2 span {
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: underline;
}



.UloginBoxformform input {
    width: 100%;
    padding: 2.5% 0;
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    text-indent: 10px;
    // margin-bottom: 5% !important;
    color: #7B8499;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.UloginBoxformform input::placeholder {
    color: #7B8499;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.UloginBoxformform .vendorttrtrtr button {
    width: 40%;
    height: 44px;
    border-radius: 10px;
    text-transform: capitalize;
    // background: #16469D;
    margin: 5% 0;

}

.UloginBoxformform a {
    text-align: end !important;
    color: #16469D;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* padding-bottom: 8%; */

}

.UloginBoxformform h5 {
    text-align: start !important;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
}

.Uloginimage h4 {
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
}

.Uloginimage h6 {
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    text-decoration: none !important;
}

.UloginBoxformform h5 span {
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.UloginBoxformform .Uloginimage {
    color: #16469D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.selecttab {
    padding: 3% 0;
    border-radius: 6px;
    border: 1px solid #CAD0DB;

}

.selecttab option {
    padding: 3% 0;
    font-size: 15px;

}

.UloginFormstartst .box1 textarea {
    resize: none;
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    padding: 2%;

}

.box3 input {
    margin-bottom: 10% !important;
}


.thirdModalsecound h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 3%;
    margin-top: 1%;
}

.thirdModalsecound h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1%;
}

.forgotOTPInput input {
    color: #868686;
    font-size: 25px;
}

.forgotVendorOTPInput {
    margin-bottom: 3%;
}

.forgotVendorOTPInput fieldset {
    border: none !important;
    border-radius: 0;
    border-bottom: 2px solid #d0d0d0 !important;

}

.forgotVendorOTPInput input {
    color: #868686;
    font-size: 25px;
}


.forgotVendorOTPInput button {
    border-radius: 10px;
    width: 100%;
    background: #16469D;
    text-transform: capitalize;
    margin-bottom: 5%;

}

.thirdModalsecound button {
    width: 100%;
    margin-top: 3%;
    margin-bottom: 1%;
}

.resendMid {
    display: flex;
    text-align: center;
    justify-content: center;
}


/* ----------------------drop down--------------- */
.selectwrapper {
    width: 100%;
    margin-bottom: 5%;
}

.selectdropdown {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
}

.selectdropdown:focus {
    border-color: #6c63ff;
    outline: none;
}

.selectoptions {
    display: none;
    position: absolute;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.selectoptions {
    padding: 20px !important;
    cursor: pointer;
}

.selectoptions:hover {
    background-color: #6c63ff;
    color: white;
}

.BoxInfovendor {
    width: 100% !important;
    text-align: center;
    margin-top: 10%;
}

.BoxInfovendor h4 {
    width: 100% !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.BoxInfovendor h6 {
    width: 100% !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}





.phoneinputtt button {
    // width: 55% !important;

    color: #fff !important;
    background-color: #16469d;
    border-radius: 0 6px 6px 0 !important;
    // border: 2px solid #16469d !important;
}
.css-1s2uunm-MuiButtonBase-root-MuiButton-root {
    padding: 8px 8px !important;
}
.phoneinputtt button:hover {
    // background-color: #1976d2 !important;
    // border: 2px solid #1976d2 !important;
    // color: #fff;
    background-color:  #133F8D;
    color: #fff;
}

.react-tel-input .form-control {

    width: 300px;
   }
  



.box2 button{
    background-color: #16469d;
}
.box2 button:hover{
    background-color: #285ab4;
}
//   ------------------------------------------------------------

@media (max-width: 1400px) {
    .UloginvendoStart {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .UloginBoxformform {
        width: 80% !important;
    }

    .BoxInfovendor h4 {
        font-size: 24px;
    }

    .BoxInfovendor h6 {
        font-size: 19px;
    }

}
@media (max-width: 1280px) {
    .react-tel-input .form-control {
        width: 220px;
        height: 20px;
       }
    }
@media (max-width: 1024px) {
    .react-tel-input .form-control {
        width: 200px;
       }
      
    }
// @media (max-width: 1280px) {
//     .react-tel-input .form-control {
//         width: 220px;
//        }
//     }

@media (max-width: 998px) {
    .UloginBox2Vendor {
        display: none !important;
    }

    .UloginBoxformform {
        width: 80%;
    }
    .react-tel-input .form-control {
        width: 500px;
       }
      
    .phoneinputtt {
        width: 100%;
        display: grid;
        grid-template-columns: 75% auto;
    }

    .UloginFormstartst {
        width: 100% !important;
    }
}
@media (max-width: 768px) {
    .react-tel-input .form-control {
        width: 400px;
       }
      
}
@media (max-width: 620px) {
    .react-tel-input .form-control {
        width: 300px;
       }
      
}
@media (max-width: 480px) {
    .react-tel-input .form-control {
        width: 250px;
       }
      
}

@media (max-width: 415px) {
    .phoneinputtt {
        display: flex;
        width: 100% !important;
    }

    .vendorttrtrtr input {
        padding: 4% 0;
    }

    .UloginFormstartst .box1 h1 {
        font-size: 20px;
    }

    .react-tel-input .form-control {
        width: 220px;
    }

    .box2 button {
        margin-top: 10%;
        width: 50%;
    }

    .thirdModalsecound {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .phoneinputtt input {
        width: 14.1ch;
        background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 2ch, transparent 0, transparent 3ch) 0 100%/14ch 4px no-repeat;
        font: 3ch "Droid Sans Mono", "Consolas", monospace;
        letter-spacing: 2ch;
        text-align: center;
        text-indent: -1;
        outline: none;
        cursor: none;
        caret-color: transparent;
    }
}