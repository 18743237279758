.ProjectDescriptionPageMain1 {
    background-image: url('https://res.cloudinary.com/dxluokypg/image/upload/v1683263875/abouts-banner-s_ykgb09.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    width: 100%;
    height: 40vh;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    margin-bottom: 5%;
    box-sizing: border-box;
    padding: 20px 0;
}

.ProjectDescriptionPageBG1 {
    display: flex;
    width: 90%;
   
    box-sizing: border-box;
    flex-direction: column;
}
.ProjectDescriptionPageBG1>div *
{
    background-color: transparent !important;
    color: white !important;

}


@media(max-width:'1280px') {
 

    .ProjectDescriptionPageBG1 h3 {
        font-size: 35px;
    }

    .ProjectDescriptionPageBG1 h6 {
        font-size: 18px;
    }
}
@media(max-width:'1024px') {
   
    .ProjectDescriptionPageBG1 h3 {
        font-size: 30px;
    }

    .ProjectDescriptionPageBG1 h6 {
        font-size: 17px;
    }
}

@media(max-width:'820px') {
  
    .ProjectDescriptionPageBG1 h3 {
        font-size: 25px !important;
    }

    .ProjectDescriptionPageBG1 h6 {
        font-size: 16px;
    }
}
@media(max-width:'768px') {
    .ProjectDescriptionPageMain1 {
    height: 30vh;
    align-items: flex-end;
    }
    .ProjectDescriptionPageBG1 h3 {
        font-size: 22px !important;
    }

    .ProjectDescriptionPageBG1 h6 {
        font-size: 15px;
    }
}

@media(max-width:'540px') {
    

    .ProjectDescriptionPageBG1 h3 {
        font-size: 20px !important;
    }

    .ProjectDescriptionPageBG1 h6 {
        font-size: 12px;
        line-height: 20px;
    }
}

@media(max-width:'415px') {
  
   

    .ProjectDescriptionPageBG1 h3 {
        font-size: 20px;
    }

    .ProjectDescriptionPageBG1 h6 {
        font-size: 12px;
    }
}