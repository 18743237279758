

//Updated Forgot OTP SCSS file: 

.vendorEmailVerOTPMain {
    display: flex;
    width: 100%;
}

.vendorEmailVerStart {
    display: flex;
    width: 100%;
    justify-content: space-between;
}



//Second Page
.EmailVerOTPLOginBox2 {
    width: 50%;
    display: flex;
    flex-direction: column;
}


.EmailVerOTPLOginBox2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-color: #F6FAFF;
    height: 100vh;
}

.EmailVerOTPBoxSTart2 {
    width: 50%;
}


.EmailVerOTPBoxSTart2 .EmailVerOTPMEdiaBox2 {
    width: 100% !important;

}

.EmailVerOTPMEdiaBox2 img {
    border-radius: 2%;
    width: 100%;
    object-fit: fill !important;

}
.EmailVerOTPBoxSTart2 ul .slick-active {
    width: 40px !important;
}

.EmailVerOTPBoxSTart2 ul .slick-active button {
    width: 40px !important;
}

.EmailVerOTPBoxSTart2 .slick-dots li button:before {
    width: 15px !important;
    content: '';
    background-color: gray;
    height: 5px;
    border-radius: 6px;
}

.EmailVerOTPBoxSTart2 ul .slick-active button::before {
    width: 40px !important;
    content: '';
    background-color: black !important;
    height: 5px;

}

.EmailVerrrOTPBOxInfo {
    width: 100 ;
    text-align: center;
    margin-top: 10%;
}

.EmailVerrrOTPBOxInfo h4 {
    width: 100 !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.EmailVerrrOTPBOxInfo h6 {
    width: 100 !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



@media(max-width:768px) {
    .EmailVerrrOTPBOxInfo h4 {
        font-size: 22px;
    }
    
    .EmailVerrrOTPBOxInfo h6 {
        font-size: 17px;
    }
}

@media(max-width:820px) {
    .EmailVerOTPLOginBox2 {
        display: none;
    }
    
    
}